.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  margin-bottom: 20px;
}
body {
  font-family: "Open Sans", sans-serif;
  background-color: #f7f9fb;
}

.right-text {
  text-align: right;
}
.names {
  width: 35%;
}
.middle-page {
  height: 100vh;
}
.input-size {
  width: 35px;
  text-align: center;
  margin-top: 10px;
}

.bdr {
  border-radius: 6px;
  overflow: hidden;
}
.text-justify{
  text-align: justify;
}